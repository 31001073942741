import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSnackbar } from 'src/components/snackbar';
import "./bulk_upload.css";
// config
import { HOST_API } from "src/config-global";
// components
import FormProvider, { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify";
import "./bulk_upload.css";
import { useServerAPI } from "src/api/ServerAPI";

const style = {
};

export default function AddInfluencerModal(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [errorMsg, setErrorMsg] = useState("");

  const [userType, setUserTye] = useState(
    localStorage.getItem("sessionUserType")
  );

  const [region, setregion] = useState("");
  const [regionError, setRegionError] = useState("")
  const [urlError, setUrlError] = useState("")
  const [gender, setgender] = useState("");
  const [genderError, setGenderError] = useState("")
  const [urlMessage, setUrlMessage] = useState(false);

  const [profileUrlData, setProfileUrlData] = useState([])

  const [formErrors, setFormErrors] = useState({});

  const [socialLinkButtondisable, setsocialLinkButtondisable] = useState(true);

  const handleInfluencerregionChange = (event) => {
    setregion(
      event.target.value
    );
    setRegionError('')
  };
  const handleInfluencergenderTypeChange = (event) => {
    setgender(
      event.target.value
    );
    setGenderError('')
  };

  const defaultValues = {
  };

  const socialProfiles = {
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    tiktok: '',
  }

  const ProfileUrlSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    age: Yup.number()
      .nullable()
      .notRequired()
      .min(0)
      .max(100, 'Age must be less than 100')
      .moreThan(-1, 'Negative values not accepted'),

    facebook: Yup.string()
      .notRequired()
      .test('valid-facebook', 'Invalid Facebook username', function (value) {
        if (!value) {
          return true;
        }
        const facebookUsernamePattern = /^[a-zA-Z0-9_\-\.]+$/;
        return facebookUsernamePattern.test(value);
      }),
    twitter: Yup.string()
      .notRequired()
      .test('valid-twitter', 'Invalid Twitter username', function (value) {
        if (!value) {
          return true;
        }
        const twitterUsernamePattern = /^[a-zA-Z0-9_]+$/;
        return twitterUsernamePattern.test(value);
      }),
    instagram: Yup.string()
      .notRequired()
      .test('valid-instagram', 'Invalid Instagram username', function (value) {
        if (!value) {
          return true;
        }
        const instagramUsernamePattern = /^[a-zA-Z0-9_\-\.]+$/;
        return instagramUsernamePattern.test(value);
      }),
    youtube: Yup.string()
      .notRequired()
      .test('valid-youtube', 'Invalid YouTube username.', function (value) {
        if (!value) {
          return true;
        }
        const youtubeUsernamePattern = /^[a-zA-Z0-9_]+$/;
        return youtubeUsernamePattern.test(value);
      }),
    tiktok: Yup.string()
      .notRequired()
      .test('valid-tiktok', 'Invalid TikTok username.', function (value) {
        if (!value) {
          return true;
        }
        const tiktokUsernamePattern = /^[a-zA-Z0-9_\-\.]+$/;
        return tiktokUsernamePattern.test(value);
      }),
  })

  const methods = useForm({
    resolver: yupResolver(ProfileUrlSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const Authorization = `Bearer ${localStorage.getItem("accessToken")}`;

  const serverAPI = useServerAPI();

  const onSubmit = handleSubmit(async (profileData) => {
    profileData.region = region;
    profileData.gender = gender;

    if (profileUrlData.length === 0) {
      setUrlError('At least one platform is required')
      return;
    }

    if (!profileUrlData) {
      setUrlMessage(true)
      setErrorMsg(false)
      return;
    }

    const socialData = {
      email: profileData.email,
      name: profileData.name,
      age: profileData.age,
      region: profileData.region,
      gender: profileData.gender,
      agency: profileData.agency,
      social_profiles: profileUrlData
    };

    const apiUrl = `${HOST_API}/api/influencer/addinfluencer`;

    if (Object.values(profileUrlData).every(value => !value)) {
      setUrlMessage(true);
    } else {
      setUrlMessage(false)

      const header = {
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(socialData),
      };

      try {
        fetch(apiUrl, {
          method: "POST",
          headers: header.headers,
          body: header.body,
        })
          .then((response) => response.json())
          .then((response) => {
            if (!response.success) {
              enqueueSnackbar(response.errors[0].detail, { variant: 'error' });
            }
            else {
              serverAPI.authMe();
              if (window.$productFruits) {
                const username = localStorage.getItem('username');
                const hasAddedCreator = true;
                const email = localStorage.getItem('email');
                const role = localStorage.getItem('role');
                const isFirstLogin = localStorage.getItem('isFirstLogin');
                window.$productFruits.push(['init', '14xalvdxkm5ZcfeJ', 'en', {
                  username,
                  email,
                  role,
                  props: { isFirstLogin, hasAddedCreator }
                }]);
              }
              enqueueSnackbar("Creator created successfully!")
              props.onClose();
              props.setCreateInfluencerManually(true);
            }
          })
          .catch((error) => {
            console.log('Add Creators Manually', error);
            enqueueSnackbar( error.message, { variant: 'error' });
            console.error("API Error:", error);
          });
      }
      catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        console.error("API Error:", error);
      }
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, textAlign: "center" }}>
      <div className="add_influencer_header">Add Creators Manually</div>
    </Stack>
  );

  const handleKeyPress = (event) => {
    if (event.key === 'e') {
      event.preventDefault();
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const updatedProfile = {
      profile_url: value,
      platform: name
    };
    setProfileUrlData(prevProfileData => {
      const index = prevProfileData.findIndex(item => item.platform === name);
      if (index !== -1) {
        if (value !== '') {
          return prevProfileData.map(item => (item.platform === name ? updatedProfile : item));
        } else {
          return prevProfileData.filter(item => item.platform !== name);
        }
      } else if (value !== '') {
        return [...prevProfileData, updatedProfile];
      }
      return prevProfileData;
    });

    if (value) {
      setUrlError(false);
      setUrlMessage(false);
    }
    setsocialLinkButtondisable(false)

    try {
      await ProfileUrlSchema.validateAt(name, { ...profileUrlData, [name]: value });
      setFormErrors(prevErrors => ({ ...prevErrors, [name]: null }));
    } catch (err) {
      setFormErrors(prevErrors => ({ ...prevErrors, [name]: err?.errors?.[0] }));
      setsocialLinkButtondisable(true)
    }
  }

  useEffect(() => {
    const hasErrors = Object.values(formErrors).some(error => error !== null);
    setsocialLinkButtondisable(hasErrors)
  }, [formErrors]);

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      <div className="parent_box">
        <span className="textLabel">Name</span>
        <RHFTextField name="name" placeholder="Name" />
      </div>
      <span style={{ marginBottom: '-20px' }} className="textLabel">Add Platforms</span>
      {Object.keys(socialProfiles).map((link) => (
        <div key={link}>
          <RHFTextField
            name={link}
            placeholder={`profile name`}
            autoComplete={'off'}
            onChange={handleChange}
            style={{ backgroundColor: '#F6F6F6', borderRadius: '8px', border: urlError ? '0.5px solid #FD6341' : 'none' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    width={24}
                    icon={
                      (link === 'facebook' && 'logos:facebook') ||
                      (link === 'instagram' && 'skill-icons:instagram') ||
                      (link === 'twitter' && 'simple-icons:x') ||
                      (link === 'youtube' && 'ant-design:youtube-filled') ||
                      (link === 'tiktok' && 'ic:round-tiktok') ||
                      ''
                    }
                    color={
                      (link === 'facebook' && '#1877F2') ||
                      (link === 'instagram' && '#DF3E30') ||
                      (link === 'twitter' && '#000000') ||
                      (link === 'youtube' && '#FF0000') ||
                      (link === 'tiktok' && '#000000') ||
                      ''
                    }
                  />
                  <span style={{ fontSize: '14px', marginLeft: '5px' }}>@</span>
                </InputAdornment>
              ),
            }}
          />
          {formErrors[link] && (
            <Typography variant="caption" color="error"
              style={{ marginLeft: '15px', fontSize: '12px', color: '#FD6341' }}
            >
              {formErrors[link]}
            </Typography>
          )}
        </div>
      ))}
      {urlError !== '' && (
        <div
          className="customError"
          style={{ color: '#FF5630', fontSize: '12px' }}
        >
          {urlError}
        </div>
      )}
      <div className="parent_box">
        <span className="textLabel">Email</span>
        <RHFTextField name="email" placeholder="Email" />
      </div>
      <div className="add-influencer-wrapper" style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="parent_box" style={{ flex: '1', width: '120px' }}>
          <span className="textLabel">Age</span>
          <RHFTextField
            type="number"
            name="age"
            placeholder="Years"
            onKeyPress={handleKeyPress}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1', marginLeft: '20px', marginRight: '20px', width: '120px' }}>
          <div className="parent_box">
            <span className="textLabel">Region</span>

            <Select
              style={{ backgroundColor: '#F6F6F6' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="add-influencer-menu"
              value={region}
              onChange={handleInfluencerregionChange}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="united_states">US</MenuItem>
              <MenuItem value="canada">Canada</MenuItem>
              <MenuItem value="uk">UK</MenuItem>
              <MenuItem value="europe">Europe</MenuItem>
              <MenuItem value="middle_east">Middle East</MenuItem>
              <MenuItem value="india">India</MenuItem>
              <MenuItem value="asia">Asia</MenuItem>
              <MenuItem value="australia">Australia</MenuItem>
            </Select>
          </div>
          {regionError !== '' && (
            <div
              className="customError"
              style={{ color: '#FF5630', fontSize: '12px' }}
            >
              {regionError}
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1', width: '120px' }}>
          <div className="parent_box">
            <span className="textLabel">Gender</span>
            <Select
              style={{ backgroundColor: '#F6F6F6' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="add-influencer-menu"
              value={gender}
              onChange={handleInfluencergenderTypeChange}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="non_binary">Non-binary</MenuItem>
            </Select>
          </div>
          {genderError !== '' && (
            <div
              className="customError"
              style={{ color: '#FF5630', fontSize: '12px' }}
            >
              {genderError}
            </div>
          )}
        </div>
      </div>

      <LoadingButton
        style={!socialLinkButtondisable ? { backgroundColor: '#458DE1' } : {}}
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        disabled={socialLinkButtondisable}
      >
        Create
      </LoadingButton>
    </Stack>
  );
  return (
    <Box sx={style}>
      {userType !== "brand" && (
        <FormProvider methods={methods} onSubmit={onSubmit} classname="upload_influencer_modal">
          {renderHead}
          {renderForm}
        </FormProvider>
      )}
    </Box>
  )
}
